import * as Prebid from 'BaxterScript/version/web/provider/google/Prebid';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Slot } from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import { GoogleAdsConfig } from 'BaxterScript/types/ProviderSettings/GoogleAds';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config) => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig;
  const prebidSettings = providerSettings.prebid;
  return !(
    (prebidSettings?._ && Object.values(prebidSettings._).some((item) => item?.enabled === true)) ||
    (prebidSettings && Object.values(prebidSettings).some((item) => item?.enabled === true))
  );
};

export const init = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][INIT]');
  Prebid.init();
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][DEPENDENCIES]');
  return Prebid.dependencies();
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][LOADED]');
  Prebid.loaded();
};

export const setTargeting = (targeting) => {
  console.info('[SLOTS][GOOGLEADSPREBID][SETTARGETING]', targeting);
  Prebid.setTargeting(targeting);
};

export const transform = async (pageId, containerId, slotId, params): Promise<Slot> => {
  console.info('[SLOTS][GOOGLEADSPREBID][TRANSFORM]', pageId, containerId, slotId, params, id);
  return Prebid.transform(pageId, containerId, slotId, params, id);
};

const getPrebidExternal = (slot) => {
  const external: unknown[] = [];
  const bids = Prebid.getBids(slot);
  const sizes = slot.prebidSizes;
  let externalBase = {};
  if (sizes.length) {
    externalBase = {
      ...externalBase,
      sizes,
      mediaTypes: {
        banner: { sizes },
      },
    };
  }
  if (bids.path) {
    external.push({
      ...externalBase,
      code: slot.path,
      bids: bids.path,
    });
  }
  if (bids.id) {
    external.push({
      ...externalBase,
      code: slot.innerId,
      bids: bids.id,
    });
  }

  return external;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const create = (slot: Slot, biddersCallback) => {
  console.info('[SLOTS][GOOGLEADSPREBID][CREATE]', slot);
  const { pbjs } = globalThis;
  if (pbjs) {
    const external = getPrebidExternal(slot);
    console.debug('[SLOTS][GOOGLEADSPREBID][CREATE]', slot, external);
    pbjs.que.push(() => {
      try {
        pbjs.addAdUnits(external);
        biddersCallback();
      } catch (e) {
        console.error('[SLOTS][GOOGLEADSPREBID][CREATE]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[GOOGLEADSPREBIDCREATE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADSPREBID][CREATE]`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[GOOGLEADSPREBIDCREATE]' });
  }
};

export const load = (prebidSlots: Slot[], biddersCallback) => {
  console.info('[SLOTS][GOOGLEADSPREBID][LOAD]', prebidSlots);
  if (!prebidSlots.length) {
    biddersCallback();
  }
  const { pbjs } = globalThis;
  if (pbjs) {
    pbjs.que.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] requesting bids');
        const slotCodesAndIds = [...prebidSlots.map((slot) => slot.innerId), ...prebidSlots.map((slot) => slot.path)];
        const slotCodes = prebidSlots.map((slot) => slot.path);
        newRelicMetrics.reportMetric(NewRelicMetric.PREBID_REQUESTED_BIDS);
        pbjs.requestBids({
          adUnitCodes: slotCodesAndIds,
          bidsBackHandler: (bids, timedOut) => {
            try {
              if (timedOut) {
                console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] timed out', bids);
                newRelicMetrics.reportMetric(NewRelicMetric.PREBID_TIMED_OUT);
              } else {
                console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] successfully returned', bids);
                newRelicMetrics.reportMetric(NewRelicMetric.PREBID_SUCCESSFULLY_RETURNED);
              }
              globalThis.googletag.cmd.push(() => {
                try {
                  console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] pbjs.setTargetingForGPTAsync(...)', bids);
                  pbjs.setTargetingForGPTAsync(slotCodes);
                  biddersCallback();
                } catch (e) {
                  console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
                  newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
                    command: '[GOOGLEADSPREBIDLOAD]',
                    message: (e as Error).message,
                  });
                  throw e;
                }
              });
            } catch (e) {
              console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
              newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
                command: '[GOOGLEADSPREBIDLOAD][BIDSBACKHANDLER]',
                message: (e as Error).message,
              });
              throw e;
            }
          },
        });
      } catch (e) {
        console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[GOOGLEADSPREBIDLOAD]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADSPREBID][LOAD]`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[GOOGLEADSPREBIDLOAD]' });
  }
};

export const refresh = (slot: Slot, biddersCallback) => {
  console.info('[SLOTS][GOOGLEADSPREBID][REFRESH]', slot);
  const { pbjs } = globalThis;
  if (pbjs) {
    pbjs.que.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADSPREBID][REFRESH] requesting bids');
        newRelicMetrics.reportMetric(NewRelicMetric.PREBID_REQUESTED_BIDS);
        pbjs.requestBids({
          adUnitCodes: [slot.innerId, slot.path],
          bidsBackHandler: (bids, timedOut) => {
            try {
              if (timedOut) {
                console.debug('[SLOTS][GOOGLEADSPREBID][REFRESH] timed out', bids);
                newRelicMetrics.reportMetric(NewRelicMetric.PREBID_TIMED_OUT);
              } else {
                console.debug('[SLOTS][GOOGLEADSPREBID][REFRESH] successfully returned', bids);
                newRelicMetrics.reportMetric(NewRelicMetric.PREBID_SUCCESSFULLY_RETURNED);
              }
              globalThis.googletag.cmd.push(() => {
                try {
                  console.debug('[SLOTS][GOOGLEADSPREBID][REFRESH] pbjs.setTargetingForGPTAsync(...)', bids);
                  pbjs.setTargetingForGPTAsync([slot.path]);
                  biddersCallback();
                } catch (e) {
                  console.error('[SLOTS][GOOGLEADSPREBID][REFRESH]', e);
                  newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
                    command: '[GOOGLEADSPREBIDREFRESH]',
                    message: (e as Error).message,
                  });
                  throw e;
                }
              });
            } catch (e) {
              console.error('[SLOTS][GOOGLEADSPREBID][REFRESH]', e);
              newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
                command: '[GOOGLEADSPREBIDREFRESH][BIDSBACKHANDLER]',
                message: (e as Error).message,
              });
              throw e;
            }
          },
        });
      } catch (e) {
        console.error('[SLOTS][GOOGLEADSPREBID][REFRESH]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[GOOGLEADSPREBIDREFRESH]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADSPREBID][LOAD]`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[GOOGLEADSPREBIDLOAD]' });
  }
};

export const remove = (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADSPREBID][REMOVE]', slots);
  return Prebid.remove(slots, id);
};

export default {
  dependencies,
  init,
  loaded,
  transform,
  create,
  load,
  refresh,
  remove,
  setTargeting,
};
